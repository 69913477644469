<template>
  <div class="grid grid-cols-9 mt-60 lg:mt-30">
    <div
      class="col-start-1 col-end-10 px-3 mt-6 lg:col-end-5 lg:pl-30 lg:my-auto lg:px-0 order-last lg:order-firsto"
    >
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider order-last lg:order-first"
        >This is our personal space. Here the 369 team navigates and creates
        high impact services for the NFT community and end users. <br />
        We encourage leisure hours and team chats to imagine together solutions
        to real or possible problems on Web3, as well as applications or
        services that facilitate its use and adoption. <br />
        Who knows... Maybe the next Facebook will come from here.
      </span>
    </div>
    <div
      class="relative col-start-4 col-end-10 lg:col-start-6 lg:col-end-10 order-first lg:order-last"
    >
      <img src="../assets/369Project/block-orange.svg" alt="block" />
      <span
        class="text-white font-medium uppercase text-4xl leading-9 lg:text-6xl absolute bottom-2 -left-24 lg:bottom-12 lg:left-3 tracking-wider"
        >369 Labs</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LandLabs",
};
</script>
