<template>
  <div class="grid grid-cols-9 mt-60 lg:mt-0">
    <div class="relative col-start-1 col-end-7 lg:col-end-5">
      <img src="../assets/369Project/block-purple.svg" alt="block" />
      <span
        class="text-white font-medium uppercase text-4xl leading-9 lg:text-6xl absolute bottom-3 left-52 lg:bottom-36 lg:left-44 tracking-wider"
        >Magna Token</span
      >
    </div>
    <div
      class="col-start-1 col-end-10 px-3 mt-6 lg:col-start-6 lg:col-end-10 lg:my-auto lg:pr-30 lg:px-0"
    >
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider"
        ><b>MAGNA</b> is our Utility Token. <br />
        This token serves: As a utility for the entire ecosystem of applications
        and services that 369 develops. <br />
        To use in the Metaverse gaming ecosystem. To redeem for Events,
        Products, Courses, Discounts, Benefits and more.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandMagna",
};
</script>
