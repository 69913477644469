<template>
  <div class="grid grid-cols-9 mt-60 lg:mt-30">
    <div
      class="col-start-1 col-end-10 px-3 mt-6 lg:col-end-5 lg:pl-30 lg:my-auto lg:px-0 order-last lg:order-first"
    >
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider"
        >We make your ideas come true. At the 369 Factory, we take care of
        everything necessary for your NFT project to go out into the world. You
        can hire all our services or one in particular.
      </span>

      <ul
        class="text-white text-base lg:text-lg font-medium leading-12 lg:leading-8 tracking-wider list-disc ml-30 lg:ml-60 mt-15"
      >
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          NFT Blockchain development at Solidity
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          Web 3.0 development
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          Centralized and decentralized hosting solution
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          UX/UI design
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          NFT Art generation
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          Financial and artistic advice
        </li>
        <li
          class="text-white text-sm lg:text-lg font-extralight leading-8 tracking-wider"
        >
          Community Management and launch strategy
        </li>
      </ul>
    </div>
    <div
      class="relative col-start-4 col-end-10 lg:col-start-6 lg:col-end-10 order-first lg:order-last"
    >
      <img src="../assets/369Project/block-yellow.svg" alt="block" />
      <span
        class="text-white font-medium uppercase text-4xl leading-9 lg:text-6xl absolute bottom-16 -left-24 lg:bottom-44 lg:left-6 tracking-wider"
        >369 <br />
        Factory</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LandFactory",
};
</script>
