<template>
  <div class="grid grid-cols-9 mt-60 lg:mt-0">
    <div class="relative col-start-1 col-end-7 lg:col-end-5">
      <img src="../assets/369Project/block-green.svg" alt="block" />
      <span
        class="text-white font-medium uppercase text-4xl leading-9 lg:text-6xl absolute bottom-4 left-38 lg:bottom-44 lg:left-44 tracking-wider"
        >369 <br />
        Incubator</span
      >
    </div>
    <div
      class="col-start-1 col-end-10 px-3 mt-6 lg:col-start-6 lg:col-end-10 lg:pr-30 lg:my-auto lg:px-0"
    >
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider"
        >Start your own NFT project at once. With the 369 Incubator, your
        fantastic ideas will find their way to materialize. We offer you the
        resources and support to start your own project. You can apply for full
        or partial financing.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandIncubator",
};
</script>
