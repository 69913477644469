<template>
  <div class="bg-darkGray pt-75">
    <LandHeader></LandHeader>
    <Land369Project></Land369Project>
    <LandFactory></LandFactory>
    <LandIncubator></LandIncubator>
    <LandLabs></LandLabs>
    <LandMagna></LandMagna>
    <LandFooter></LandFooter>
  </div>
</template>

<script>
import LandHeader from "../components/LandHeader.vue";
import Land369Project from "../components/Land369Project.vue";
import LandFactory from "../components/LandFactory.vue";
import LandIncubator from "../components/LandIncubator.vue";
import LandLabs from "../components/LandLabs.vue";
import LandMagna from "../components/LandMagna.vue";
import LandFooter from "../components/LandFooter.vue";

export default {
  name: "Land",
  components: {
    LandHeader,
    Land369Project,
    LandFactory,
    LandIncubator,
    LandLabs,
    LandMagna,

    LandFooter,
  },
};
</script>
