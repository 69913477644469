<template>
  <div class="grid grid-cols-9 relative">
    <img
      src="../assets/369Project/369project-black.svg"
      alt="logo"
      class="hidden lg:inline-block col-start-1 col-end-10"
    />
    <img
      src="../assets/369Project/369project-black-mobile.svg"
      alt="logo"
      class="lg:hidden col-start-1 col-end-10"
    />
    <div
      class="w-210 lg:w-300 absolute bottom-2 right-24 lg:bottom-6 lg:right-40 flex flex-col items-end"
    >
      <span
        class="text-white font-extralight text-xs lg:text-sm italic leading-6"
        >« If you only knew the magnificence of the 3, 6 and 9, then you would
        have the key to the universe. »
      </span>
      <br />
      <span class="text-white font-medium text-xs lg:text-sm italic px-6"
        >Nikola Tesla</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LandHeader",
};
</script>
