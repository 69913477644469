<template>
  <div class="grid grid-col-9 pt-60 lg:pt-150 relative">
    <img
      src="../assets/369Project/369project-footer-black.svg"
      alt="logo"
      class="hidden lg:inline-block col-start-1 col-end-10"
    />
    <img
      src="../assets/369Project/369project-footer-black-mobile.svg"
      alt="logo"
      class="lg:hidden inline-block col-start-1 col-end-10"
    />
    <div class="lg:w-600 absolute bottom-20 left-16 lg:bottom-54 lg:left-40">
      <span
        class="text-white font-extralight text-2xl lg:text-4xl italic leading-6"
        >Website coming soon...
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandFooter",
};
</script>
