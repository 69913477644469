<template>
  <div class="grid grid-cols-9 mt-60 lg:mt-0">
    <div class="col-start-1 col-end-7 lg:col-end-5 relative">
      <img src="../assets/369Project/block-pink.svg" alt="block" />
      <span
        class="text-white font-medium uppercase text-4xl lg:text-6xl absolute bottom-24 -right-16 lg:bottom-52 lg:left-44 tracking-wider lg:col-start-2 lg:col-end-3"
        >369 Land</span
      >
    </div>
    <div
      class="col-start-1 col-end-10 px-3 mt-6 lg:col-start-5 lg:col-end-10 lg:my-auto lg:pr-30 lg:px-0"
    >
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider"
        >We are a dynamic startup expert in Blockchain NFT. We offer software
        factory, project incubation and tools to make your craziest ideas come
        true. Jump with us into the crypto world.</span
      >
      <br /><br />
      <span
        class="text-white text-sm lg:text-lg font-medium leading-3 lg:leading-6 tracking-wider"
      >
        We have 3 areas: 369 Factory, 369 Incubator and 369 Labs</span
      >
      <br />
      <br />
      <span
        class="text-white text-sm lg:text-lg font-extralight leading-3 lg:leading-8 tracking-wider"
      >
        We are young, ambitious and enthusiastic; but above all, we are
        determined and focused. And even more important: we are waiting for you.
        Together, we will achieve the biggest goals and change the world. We
        want to form an ecosystem of projects that support each other because we
        are sure that a community is the way to make great things happen.
        <br /><br />
        369 was created to speed up the adaptation of Web3 and to develop
        applications and services that enhance it.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Land369Project",
};
</script>
